<template>
    <div @click.self="shopShow = false" style="overflow-x: hidden" v-loading.fullscreen.lock="fullscreenLoading">
        <Navigation />
        <media_screen path_arr="path_array2" :category_id="99" @screen_confirm="pageChange(1)"
            @reset_screen="pageChange(1)" />

        <el-table :cell-style="finalCellStyle" :header-cell-style="headerStyle" :data="tableData"
            style="width: 80%; margin:0 auto 20px auto;">
            <el-table-column type="expand">
                <template slot-scope="props">
                    <el-form label-position="center" inline class="demo-table-expand">
                        <el-form-item label="套餐明细:">
                            <div class="flex" style="flex-wrap: wrap;">
                                <a :href="item.case_url" target="_blank" class="order_ct" style="font-size: 1.2rem;"
                                    v-for="(item, index) in props.row.medium_list">{{
        item.title
    }}</a>
                            </div>
                        </el-form-item>
                    </el-form>
                </template>
            </el-table-column>
            <el-table-column label="套餐名称" prop="title">
            </el-table-column>
            <el-table-column label="价格">
                <template slot-scope="scope">
                    ￥{{ scope.row.price.toFixed(2) }}
                </template>
            </el-table-column>
            <el-table-column label="媒介总数" prop="num">
            </el-table-column>
            <el-table-column label="备注(仅供参考)">
                <template slot-scope="scope">
                    <td_remark :text="scope.row.des" />
                </template>
            </el-table-column>
            <el-table-column label="操作">
                <template slot-scope="scope">
                    <button class="place_but" @click="handleEdit(1, scope.row)">立即下单</button>
                    <div class="flex collect_box" style="justify-content: center;">
                        <p style="cursor: pointer;" v-show="scope.row.is_collect == 0 && user_info" class="collect"
                            @click="collectGet(scope.row)">收藏</p>
                        <p style="cursor: pointer;" v-show="scope.row.is_collect == 1 && user_info" class="collect2"
                            @click="collectGet(scope.row)">已收藏</p>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <!-- 分页器 -->
        <div class="paging_box">
            <pagination :page="page" @pageChange="pageChange" :count="count" :total_page="total_page" />
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import media_screen from '@/components/media_screen'
import { media_screen_ } from '@/util/media_screen_'
export default {
    computed: {
        ...mapState(['media_screen_data']),
    },
    components: {
        media_screen
    },
    mounted() {
        this.user_info = localStorage.getItem('user_info')
    },
    data() {
        return {
            user_info: {},
            /*数据表格*/
            tableData: [],
            shopCar: [], //购物车结算
            totalMoney: undefined, //价格总和
            Allcheck: false,
            fullscreenLoading: false,
            total_page: 0, //分页总页数
            count: 0, //总条数
            page: 1,
            // 套餐子列表显示隐藏
            selectShow: true,
            selectTable: [],
            is_collect: 0,
        }
    },
    methods: {
        pageChange(pageVal) {
            this.page = pageVal
            this.get_list()
        },
        headerStyle() {
            return {
                color: '#168ad9',
                fontSize: "14px",
                textAlign: "center",
            }
        },
        finalCellStyle(row) {
            let style = {
                textAlign: "center",
                fontSize: "14px"

            };
            return style;
        },
        selectTab(item) {
            this.selectShow = !this.selectShow
            this.selectTable = item
        },
        // 清空购物车
        empty() {
            this.$confirm('是否清空已选媒体?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    this.fullscreenLoading = true
                    // setTimeout(() => {
                    this.CarList = []
                    this.shopShow = false
                    // this.toggleSelection()
                    this.tableData.forEach((item, index) => {
                        if (item.check) {
                            return item.check = false
                        }
                    })
                    this.fullscreenLoading = false
                    // }, 500)
                    this.$message({
                        type: 'success',
                        message: '已清空已选媒体',
                    })
                })
                .catch(() => {
                    this.$message({
                        type: 'warning',
                        message: '已删除',
                    })
                })
        },
        // 结算
        handleEdit(index, row) {
            this.shopCar.push(row)
            this.$store.commit('SETACTIVEMENU', '/user/SetMenu/fill?id=8')
            this.$store.commit('shopPush', this.shopCar)
            this.$router.push('/user/SetMenu/fill?id=8')
        },
        // 获取列表数据
        get_list() {
            this.fullscreenLoading = true
            let data = media_screen_(this.$store.state.media_screen_data.date_form)
            data.page = this.page
            data.limit = 20
            this.curlGet('/api/medium/package', data).then(res => {
                if (res.data.code) {
                    this.tableData = res.data.data.list
                    this.total_page = res.data.data.total_page
                    this.count = res.data.data.count
                    this.tableData.forEach((item, index) => {
                        this.$set(this.tableData[index], 'select', false)
                        this.tableData[index].price = parseInt(this.tableData[index].price)
                    })
                }
                this.fullscreenLoading = false
            })
        },
        // 重置选择
        AllMediaTab() {
            this.mediaName = ''
            this.MoneyType = {
                id: 0,
                title: '不限'
            }
            this.SortType = {
                id: 0,
                title: '默认'
            }

            this.get_list()
        },
        // 收藏
        collectGet(item) {
            if (!this.user_info) {
                this.$message({
                    type: 'warning',
                    message: '请登录',
                    offset: 90,
                })
            } else {
                this.curlGet('/api/medium/collect', {
                    mid: item.id,
                    style: 2
                }).then(res => {
                    if (res.data.code) {
                        this.tableData.forEach((elem, index) => {
                            if (item.id == elem.id) {
                                this.tableData[index].is_collect = !this.tableData[index].is_collect
                                if (this.tableData[index].is_collect) {
                                    return this.$message({
                                        message: '收藏成功',
                                        type: 'success',
                                        offset: 80,
                                    })
                                } else {
                                    this.$message({
                                        message: '已取消收藏',
                                        type: 'warning',
                                        offset: 80,
                                    })
                                }
                            }
                        })

                        // this.get_list()
                    }
                })
            }
        },
    },
}
</script>

<style lang="scss" scoped>
@import '@/scss/Media';

::v-deep .el-form-item__label {
    font-weight: bold;
}
</style>